/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import './NotificationShade.css';

const NotificationShade = function (props) {
  return (
    <div className="shade">
      <div className="shade-header">
        NOTIFICATIONS
      </div>
      {props.children}
    </div>
  );
};

export default NotificationShade;
