import React, { useEffect, useRef, useState } from 'react'
import 'react-chat-elements/dist/main.css';
import { MessageBox } from 'react-chat-elements';
import './ChatMessage.css'


const ChatMessage = (props) =>{

    const [Text, setText] = useState("")
    useEffect(() => {
        if(props.text.includes('@')){
            let mentioned = props.text.split(' ');
            let allText = ''
            mentioned.forEach((a)=>{
                allText += a.includes('@') ? '' : a+' ';
            })
            if(props.replyInfo == null){
                let property = selfRef.current.refs.message.childNodes[0].childNodes[0].childNodes[2];
                property.innerHTML = `<b style="color: var(--blue)">${mentioned[0]}</b> ${mentioned[1] !== undefined ?allText: ''}`
            } else {
                let property = selfRef.current.refs.message.childNodes[0].childNodes[0].childNodes[3];
                property.innerHTML = `<b style="color: var(--blue)">${mentioned[0]}</b> ${mentioned[1] !== undefined ? allText: ''}`
            }
        }

        if (props.text.includes('@') && props.isMine === false){
            let property = selfRef.current.refs.message;
            property.style.backgroundColor = '#4b9fff99'
            property.style.borderLeft = '1rem solid rgb(75 159 255 / 87%)'
        }
        setText(props.text)
    }, [props])
    const selfRef = useRef(null)
    return (
        <MessageBox
            ref={selfRef}
            onReplyClick={() => { props.onReplyClick(selfRef.current.props)}}
            title={props.title}
            replyButton={true}
            titleColor={props.color}
            reply={props.replyInfo}
            onReplyMessageClick={() => console.log('reply clicked!')}
            position={props.isMine ? 'left' : 'right'}
            type={'text'}
            text={Text} />
    )
}

export default ChatMessage;