let messages = [
    {
        color: '#52489C',
        text: 'hello everyone!',
        title: 'Suraj',
        isMine: false,
        replyInfo: null
    },
    {
        color: '#EC4E20',
        text: 'hey!!',
        title: 'Mridul',
        isMine: false,
        replyInfo: null
    },
    {
        color: '#FF9505',
        text: 'Have any of you guys started M3 yet?',
        title: 'Ryan',
        isMine: false,
        replyInfo: null
    },
    {
        replyInfo: {
            title: 'Ryan',
            titleColor: '#FF9505',
            message: 'Have any of you guys started M3 yet?',
        },
        color: '#FF9505',
        text: '@Guneet wbu?',
        title: 'Ryan',
        isMine: false
    },
     {
        replyInfo: {
            title: 'Ryan',
            titleColor: '#FF9505',
            message: 'Have any of you guys started M3 yet?',
        },
        color: '#000000',
        text:'I haven\'t :((',
        title: 'Umer',
        isMine: false
    }
]

export default messages;