
import { useState } from 'react'
import spritemap from '../../assets/icons.svg'
import { ClayButtonWithIcon } from '@clayui/button';
import { ClayDropDownWithItems } from '@clayui/drop-down';
import ClayToolbar from '@clayui/toolbar';

import './TopBar.css'

const TopBar = (props) => {
    const [hasNotif] = useState(true)

    return (
        <div style={{
            width: '100% !important',
            marginLeft: '1rem'
        }}>
            <ClayToolbar>
                <ClayToolbar.Nav>
                    <ClayToolbar.Item className="text-left" expand>
                        <ClayToolbar.Section>
                            <label className="component-title">{"Chat"}</label>
                        </ClayToolbar.Section>
                    </ClayToolbar.Item>

                    <ClayToolbar.Item>
                        <ClayToolbar.Section>
                            <div className='notifs-area'>
                                {hasNotif ? <span className='notif-area--bubble'></span> : null}
                                <ClayButtonWithIcon spritemap={spritemap} symbol='bell-on' displayType="unstyled" small onClick={props.openNotifications} />
                            </div>
                        </ClayToolbar.Section>
                    </ClayToolbar.Item>

                    <ClayToolbar.Item>
                        <ClayDropDownWithItems
                            items={[
                                { href: "#one", label: "Print Chat" },
                            ]}
                            spritemap={spritemap}
                            trigger={
                                <ClayButtonWithIcon
                                    displayType="unstyled"
                                    small
                                    spritemap={spritemap}
                                    symbol="ellipsis-v"
                                />
                            }
                        />
                    </ClayToolbar.Item>
                </ClayToolbar.Nav>
            </ClayToolbar>
        </div>
    )
}

export default TopBar;