import spritemap from '../../assets/icons.svg'
import ClayButton, { ClayButtonWithIcon } from '@clayui/button';
import ClayIcon from '@clayui/icon';
import { ClayDropDownWithItems } from '@clayui/drop-down';
import ClayToolbar from '@clayui/toolbar';
import ClayList from '@clayui/list';
import { Input } from 'react-chat-elements'
import messages from '../../assets/messages';
import { useRef, useState } from 'react';

const BottomBar = (props) => {
    const textRef = useRef(null);
    const [showAt, setShowAt] = useState(null)
    const [Participants] = useState([...new Set(messages.map(m=>m.title))])
    const onTextChange = (e) =>{
        let val = e.target.value
        let at = val.split(' ');
        if(at[0].includes('@')){
            if(showAt == null){
                setShowAt(true)
            }
        }
        console.log(at)
    }
    const setAtReply = (e)=>{
        textRef.current.input.value = '@'+e;
        setShowAt(false)
    }
    return (
        <ClayToolbar>
            <ClayToolbar.Nav>
                {props.isRightBar ? 
                    <>
                        {showAt ?  <ClayList style={{ position: 'absolute', bottom: '2rem'}}>
                            <ClayList.Header>Participants</ClayList.Header>
                            {Participants.map(p => <ClayList.Item flex style={{cursor: 'pointer'}} onClick={e=>setAtReply(p)}>
                                <ClayList.ItemField>{p}</ClayList.ItemField>
                            </ClayList.Item>)}
                        </ClayList> :null}
                    <ClayToolbar.Item style={{
                        width: '100%'}}>
                      <ClayToolbar.Section style={{
                            position: props.replyInfo !== null ? 'absolute' : 'inherit', width: props.replyInfo !== null ? '100%' : 'inherit' ,
                            bottom: props.replyInfo !== null ? '1rem' : 'auto'}}>
                            {props.replyInfo ?
                                <div style={{
                                    backgroundColor: '#ececec', borderLeft: 'solid .5rem', borderLeftColor: props.replyInfo.titleColor,
                             borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem', padding: '.5rem', paddingBottom: '1px', margin: '0rem'}}>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <p style={{ fontSize: '.8rem', fontWeight: 'bold', color: props.replyInfo.titleColor}}>{props.replyInfo.title}</p>
                                        <ClayButtonWithIcon
                                            onClick={e => props.resetReply()}
                                            displayType='unstyled'
                                            small
                                            spritemap={spritemap}
                                            symbol="times"
                                        />
                                    </div>
                                    <p style={{ fontSize: '.7rem', color:'#5a5a5a', marginBottom: '.5rem'}}>{props.replyInfo.message}</p>
                            </div> : null}
                            <Input
                                ref={textRef}
                                onChange={e=>onTextChange(e)}
                                className="bottom-input"
                                placeholder="Type here..."
                                rightButtons={
                                    <ClayButtonWithIcon
                                        onClick={(e)=>{
                                            props.onTextEntry(textRef.current?.input?.value);
                                        }}
                                        displayType="primary"
                                        small
                                        spritemap={spritemap}
                                        symbol="play"
                                    />
                                } />
                    </ClayToolbar.Section>
                    </ClayToolbar.Item></>
                : <><ClayToolbar.Item className="text-left" expand>
                    <ClayToolbar.Section>
                        <label className="component-title">{"CIS*4300 – Lecture 08"}</label>
                        <ClayIcon spritemap={spritemap} symbol="lock" />
                    </ClayToolbar.Section>
                </ClayToolbar.Item>

                <ClayToolbar.Item>
                    <ClayToolbar.Section>
                        <ClayButton displayType="secondary" onClick={() => { }}>
                            {"Share Screen"}
                        </ClayButton>

                        <ClayButton className="inline-item-after" displayType="danger" onClick={() => { }}>
                            {"End Call"}
                        </ClayButton>
                    </ClayToolbar.Section>
                </ClayToolbar.Item>

                <ClayToolbar.Item>
                    <ClayDropDownWithItems
                        items={[
                            { href: "#one", label: "View Participants" },
                            { href: "#two", label: "Show Drawing Board" },
                            { disabled: true, href: "#three", label: "Show Join Info" },
                        ]}
                        spritemap={spritemap}
                        trigger={
                            <ClayButtonWithIcon
                                displayType="unstyled"
                                small
                                spritemap={spritemap}
                                symbol="ellipsis-v"
                            />
                        }
                    />
                </ClayToolbar.Item></>}
            </ClayToolbar.Nav>
        </ClayToolbar>
    )
}

export default BottomBar;