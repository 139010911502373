import './VideoFeed.css'
import { useEffect, useRef, useState } from 'react'
import BottomBar from '../BottomBar/BottomBar'
import ClayLoadingIndicator from '@clayui/loading-indicator';
import simpsonsVideo from '../../assets/simpsons.mp4';

const VideoFeed = (props) => {
    const video = useRef(null)
    const [loading, setLoading] = useState(true)
    const [hasError, setHasError] = useState(false)

    const init_video = async () => {
        let stream = null;
        try {
            stream = await navigator.mediaDevices.getUserMedia({ video: true })
            return stream;
        } catch (err) {
            setLoading(false)
            setHasError(true)
        }
    }

    useEffect(()=>{
        if(hasError) {
            props.errorHandler()
        }
    }, [hasError, props])
    
    useEffect(() => {

        if (window.location.pathname === '/noPerm'){
            setLoading(false)
        } else {
            init_video().then(e => {
                video.current.srcObject = e;
            })
        }
    }, [])

    return (
        <>
            <div className='video-feed'>
                <div className='video-feed--feed'>
                    {loading ? <div>
                        <ClayLoadingIndicator/>
                        <p>getting permission & loading video feed..</p>
                    </div> : null}
                    <video style={{ display: loading ? 'none' : 'inherit' }} className='img-fluid' ref={video} src={simpsonsVideo} muted={true} loop={true} onLoadedMetadata={e=> {video.current.play(); setLoading(false)}}/>
                </div>
                <div className='video-feed--bar'>
                    <BottomBar/>
                </div>
            </div>
        </>
    )
}

export default VideoFeed;