import './App.css';
import VideoFeed from './components/VideoFeed/VideoFeed';
import TopBar from './components/TopBar/TopBar';
import NotificationShade from './components/NotificationShade/NotificationShade';
import { useEffect, useState } from 'react';
import spritemap from './assets/icons.svg'
import ClayAlert from '@clayui/alert';
import ClayIcon from '@clayui/icon';
import MessageList from './components/MessageList/MessageList';
import ChatMessage from './components/ChatMessage/ChatMessage';
import BottomBar from './components/BottomBar/BottomBar';
import defaultMessages from './assets/messages';


function App() {
  const [openShade, setOpenShade] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [noPerm, setNoPerm] = useState(false)
  const [replyInfo, setReplyInfo] = useState(null)

  const [messages, setMessages] = useState(defaultMessages)
  useEffect(() => {
    if (window.location.pathname === '/noPerm'){
     setNoPerm(true)
   }
  }, [])

  let toggleShade = () => {
    setOpenShade(!openShade)
  }

  let handleError = () => {
    setHasError(true)
  }

  const resetReply = () =>{
    setReplyInfo(null)
  }

  const onTextEntry = (text)=>{
    let message = {
      replyInfo: replyInfo ? replyInfo : null,
      color: '#353531',
      text: text,
      title: 'Guneet',
      isMine: true
    }
    setMessages([...messages, message])
    if (replyInfo) {
      setReplyInfo(null)
    }
  }
  const onReplyClick = (e) =>{
    let replyInfo = {
      title: e.title, 
      titleColor: e.titleColor, 
      message: e.text
    }
    setReplyInfo(replyInfo)
  }


  return (
    <>
      {hasError & !noPerm ? 
      <div className='container'>
          <ClayAlert displayType="danger" spritemap={spritemap} title="Error" variant="stripe">
            Camera permissions denied. Continue <a href={'/noPerm'}>without camera?</a>
          </ClayAlert>
          <ClayIcon style={{
            fontSize: '20rem',
            opacity: 0.5,
            margin: 'auto',
            marginTop: '1rem'
          }} spritemap={spritemap} symbol="warning"/>
      </div>
       : 
      <div className="container-fluid">
        <div className='container-left'>
            <VideoFeed errorHandler={handleError}/>
        </div>
        <div className='container-right'>
          {openShade ? <NotificationShade /> : null}
          <TopBar openNotifications={toggleShade}/>
          <MessageList>
              {messages.map((message) => {return<ChatMessage onReplyClick={e => onReplyClick(e)} {...message}/>;})}
          </MessageList>
            <BottomBar resetReply={resetReply} replyInfo={replyInfo} isRightBar onTextEntry={e=>onTextEntry(e)}/>
        </div>
      </div>}
    </>
  );
}

export default App;