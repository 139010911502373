import React from 'react'

export default function MessageList(props) {
    return (
        <div style={{
            flex: 1,
            overflow: 'auto'
        }}>
            <div
                style={{
                    flexBasis: 'bottom',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                }}>
                {props.children}
            </div>
        </div>
        
    )
}
